import React, {useState} from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import {getNewsletterFormSchema, newsletterHubspotFormId} from '../../helpers/constants/forms';
import {formPostToHubspot, validateFormData} from '../../helpers/utils/forms';
import {breakpoints, colors, ContentContainer, fontWeights} from '../../styles/theme';
import {DefaultForm, FormError, Input} from './Forms';
import {H6Mono, Link, LinkDark, P2, P2Mono} from './Typography';
import {CTAArrow} from './Clickables';
import FacebookLogo from '../../images/social-media/facebook.svg';
import InstagramLogo from '../../images/social-media/instagram.svg';
import LinkedInLogo from '../../images/social-media/linkedin.svg';
import MediumLogo from '../../images/social-media/medium.svg';
import TwitterLogo from '../../images/social-media/twitter.svg';
import YoutubeLogo from '../../images/social-media/youtube.svg';
import SwitchLogo from '../../images/switch-logo.svg';


/*
 * Constants
 */
const currentYear = new Date().getFullYear();
const socialNetworks = {
    facebook: {icon: <FacebookLogo />, url: 'https://www.facebook.com/switchpayments/'},
    instagram: {icon: <InstagramLogo />, url: 'https://www.instagram.com/switchpayments/'},
    medium: {icon: <MediumLogo />, url: 'https://medium.com/switch-blog'},
    linkedin: {icon: <LinkedInLogo />, url: 'https://www.linkedin.com/company/switchpayments/'},
    twitter: {icon: <TwitterLogo />, url: 'https://twitter.com/switchpayments'},
    youtube: {icon: <YoutubeLogo />, url: 'https://www.youtube.com/channel/UCRBQMnh916VMruvBsa4P7-A'},
};


/*
 * Private Elements
 */

// Common
const StyledFooter = styled.footer`
    background: ${colors.black};
    color: ${colors.white};
    padding-top: 100px;
    padding-bottom: 65px;

    div {
        &.legal-mobile {
            margin-top: 40px;
        }
    }

    .wrapper {
        margin-right: 25px;
        margin-left: 25px;
    }
        
    .media-links {
        margin-top: 60px;
    }

    @media (min-width: ${breakpoints.lg}) {
        .wrapper {
            margin-right: 64px;
            margin-left: 64px;
        }
    }

    @media (min-width: ${breakpoints.md}) {
        .media-links {
            margin-top: 0;
        }
    }
`;

const StyledSwitchLogo = styled(SwitchLogo)`
    margin-bottom: 48px;
`;

const FooterNavigationItem = styled(P2)`
    &:last-of-type {
        margin-bottom: 68px;
    }
    
    &.bold {
        font-weight: ${fontWeights.bold};
    }
`;

// Newsletter Form
const StyledNewsletterFormContainer = styled.div`
    flex-grow: 1;

    ${DefaultForm} {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 30px;

        .form-group {
            width: 100%;
            margin-bottom: 0.6rem;
        }

        ${Input} {
            &::placeholder {
                color: ${colors.white};
            }
        }

        .input-group-text {
            padding: 0;
            border: none;
            border-bottom: 2px solid ${colors.white};
            border-radius: 0;
            background-color: inherit;
        }

        .input-group-append {
            position: absolute;
            top: 0;
            right: 0;
            height: 39px;
            display: flex;
            align-items: center;
            background-color: ${colors.black};
            border: none;
        }
    }

    .newsletter-form-helper-elements {
        &.newsletter-form-helper-elements-pledge {
            ${H6Mono} {
                flex: auto;
            }
        }
    }

    #newsletter-form-thank-you {
        display: none;
        margin-top: 10px;
        font-weight: ${fontWeights.bold};
        background: linear-gradient(to right, ${colors.darkPurple}, ${colors.white});
        background-clip: text;
        text-fill-color: transparent;

        ${P2Mono} {
            &:first-of-type {
                margin-bottom: 25%;
            }
        }
    }
`;

// Self contained form element for newsletter subscription
const NewsletterForm = ({schema}) => {
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    let formElementClass = '';
    /* eslint-disable-next-line i18next/no-literal-string */
    let formElementOthersClass = 'row no-gutters newsletter-form-helper-elements';
    let formElementThankYouClass = '';


    const submitNewsletterForm = async event => {
        event.preventDefault();
        setIsSubmitting(true);
        setFormErrors({});

        try {
            const frontendErrors = validateFormData(formData, schema);
            if (frontendErrors) {
                setFormErrors(frontendErrors);
                return false;
            }

            const formPostResult = await formPostToHubspot(newsletterHubspotFormId, formData);
            if (!formPostResult) {
                setFormErrors({
                    message: [<Trans>Couldn&apos;t submit response. Please, try again later.</Trans>],
                });
                return false;
            }
            setIsSubmitted(true);
        } finally {
            setIsSubmitting(false);
        }
        return true;
    };


    const formElements = schema.map(input => {
        const onChange = event => {
            // Clean error message when the form values changes
            if (formErrors?.message) {
                formErrors.message = null;
            }

            setFormErrors({...formErrors, fields: {...formErrors?.fields, [input.name]: null}});
            setFormData({...formData, [input.name]: input.getValue ? input.getValue(event) : event.target.value});
        };

        const errorMessages = formErrors.fields?.[input.name];
        // eslint-disable-next-line i18next/no-literal-string
        const errorClass = errorMessages && errorMessages.length ? 'error' : '';

        return (
            <div className="form-group" key={input.name}>
                <input.element
                    className={`form-control ${errorClass}`}
                    name={input.name}
                    value={formData[input.name] || ''}
                    onChange={onChange}
                    disabled={isSubmitting}
                    {...input.attributes}
                />
                <button type="submit" className="input-group-append" onClick={submitNewsletterForm}>
                    <CTAArrow colored />
                </button>

                <FormError messages={errorMessages} />
            </div>
        );
    });

    if (isSubmitted) {
        /* eslint-disable-next-line i18next/no-literal-string */
        formElementClass += 'd-none';
        /* eslint-disable-next-line i18next/no-literal-string */
        formElementOthersClass += ' d-none';
        /* eslint-disable-next-line i18next/no-literal-string */
        formElementThankYouClass = 'd-block';
    }

    return (
        <StyledNewsletterFormContainer>
            <div className={`${formElementOthersClass} newsletter-form-helper-elements-pledge`}>
                <H6Mono><Trans>Join our newsletter.</Trans></H6Mono>
                <H6Mono><Trans>We promise not to spam you.</Trans></H6Mono>
            </div>

            <div className="row no-gutters">
                <DefaultForm className={`input-group ${formElementClass}`}>
                    {formElements}
                    <FormError messages={formErrors.message} />
                </DefaultForm>
                <div id="newsletter-form-thank-you" className={formElementThankYouClass}>
                    <P2Mono><Trans>Thank you for subscribing.</Trans></P2Mono>
                    <P2Mono>
                        <Trans>
                            You&apos;ll be the first to receive our updates. You should expect a confirmation email
                            soon.
                        </Trans>
                    </P2Mono>
                </div>
            </div>

            <div className={formElementOthersClass}>
                <CopyrightAndLegal>
                    <Trans>
                        By moving forward, you are agreeing to our Privacy Policy as well as with our Terms and
                        Conditions.
                    </Trans>
                </CopyrightAndLegal>
            </div>
        </StyledNewsletterFormContainer>
    );
};

NewsletterForm.propTypes = {
    schema: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

// Copyright
const CopyrightAndLegal = styled(P2Mono)`
    color: ${colors.mediumGrey};
    margin-bottom: 0;
`;

const CopyrightLink = styled(LinkDark)`
    color: ${colors.mediumGrey};
    text-decoration: underline;
  
    &:visited {
        color: ${colors.mediumGrey};
    }
  
    // ":hover" must come AFTER ":visited" so that it supersedes the color in the ":visited" selector
    &:hover {
        color: ${colors.darkGrey};
        text-decoration: underline;
    }
`;

// Media
const StyledMediaLogoDiv = styled.div`
    padding: 0;

    svg {
        * {
            fill: ${colors.mediumGrey};
        }

        &:hover {
            * {
                fill: ${colors.darkGrey};
            }
        }
    }
`;

const MediaLogoLink = ({socialNetwork}) => (
    <StyledMediaLogoDiv className="col-2">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={socialNetworks[socialNetwork].url} target="_blank" rel="noopener">
            {socialNetworks[socialNetwork].icon}
        </a>
    </StyledMediaLogoDiv>
);

MediaLogoLink.propTypes = {
    socialNetwork: PropTypes.string.isRequired,
};


/*
 * Public Elements
 */
const Footer = () => {
    const {t} = useTranslation();

    const newsletterFormSchema = getNewsletterFormSchema(t);

    return (
        <StyledFooter>
            <ContentContainer>
                <div className="row no-gutters wrapper">
                    <div className="col-md-9">
                        <div className="row no-gutters">
                            <div className="col-6">
                                <div className="row no-gutters">
                                    <div className="col-md">
                                        <div>
                                            <FooterNavigationItem className="bold">
                                                <Trans>Products</Trans>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/product/processing"><Trans>Processing</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/product/dynamic-routing">
                                                    <Trans>Dynamic Routing</Trans>
                                                </LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/product/vault"><Trans>Vault</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/product/reconciliation">
                                                    <Trans>Reconciliation</Trans>
                                                </LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/product/risk"><Trans>Risk</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/product/analytics"><Trans>Analytics</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/product/terminal">
                                                    <Trans>Terminal</Trans>
                                                </LinkDark>
                                            </FooterNavigationItem>
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div>
                                            <FooterNavigationItem className="bold">
                                                <Trans>Use Cases</Trans>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/use-cases/merchants"><Trans>Merchants</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/use-cases/isvs"><Trans>ISVs</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/use-cases/psps"><Trans>PSPs</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/use-cases/acquirers"><Trans>Acquirers</Trans></LinkDark>
                                            </FooterNavigationItem>
                                        </div>

                                        <div className="d-none d-md-block">
                                            <FooterNavigationItem className="bold">
                                                <LinkDark to="/channels"><Trans>Channels</Trans></LinkDark>
                                            </FooterNavigationItem>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="row no-gutters">
                                    <div className="col-md">
                                        <div className="d-md-none">
                                            <FooterNavigationItem className="bold">
                                                <LinkDark to="/channels"><Trans>Channels</Trans></LinkDark>
                                            </FooterNavigationItem>
                                        </div>
                                        <div>
                                            <FooterNavigationItem className="bold">
                                                <LinkDark to="https://switchpayments.com/docs/" target="_blank">
                                                    <Trans>Developers</Trans>
                                                </LinkDark>
                                            </FooterNavigationItem>
                                        </div>
                                        <div>
                                            <FooterNavigationItem className="bold">
                                                <LinkDark to="/learn"><Trans>Learn</Trans></LinkDark>
                                            </FooterNavigationItem>
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div>
                                            <FooterNavigationItem className="bold">
                                                <Trans>About</Trans>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/about/company"><Trans>Company</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/about/careers"><Trans>Careers</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/about/newsroom"><Trans>Newsroom</Trans></LinkDark>
                                            </FooterNavigationItem>
                                            <FooterNavigationItem>
                                                <LinkDark to="/about/contact"><Trans>Contact Us</Trans></LinkDark>
                                            </FooterNavigationItem>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row no-gutters d-none d-md-block">
                            <div className="col-md">
                                {/* eslint-disable-next-line i18next/no-literal-string */}
                                <CopyrightAndLegal>
                                    &copy;
                                    {' '}
                                    {currentYear}
                                    {' '}
                                    <Trans>Switch</Trans>
                                    {' '}
                                    <CopyrightLink to="/privacy-policy"><Trans>Privacy Policy</Trans></CopyrightLink>
                                </CopyrightAndLegal>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3" style={{display: 'flex', flexDirection: 'column'}}>
                        <Link className="row no-gutters" to="/">
                            <StyledSwitchLogo />
                        </Link>

                        <NewsletterForm schema={newsletterFormSchema} />

                        <div className="row no-gutters align-items-end media-links">
                            <MediaLogoLink socialNetwork="twitter" />
                            <MediaLogoLink socialNetwork="facebook" />
                            <MediaLogoLink socialNetwork="instagram" />
                            <MediaLogoLink socialNetwork="medium" />
                            <MediaLogoLink socialNetwork="linkedin" />
                            <MediaLogoLink socialNetwork="youtube" />
                        </div>

                        <div className="row no-gutters d-md-none legal-mobile">
                            <div className="col-md">
                                {/* eslint-disable-next-line i18next/no-literal-string */}
                                <CopyrightAndLegal>
                                    &copy;
                                    {' '}
                                    {currentYear}
                                    {' '}
                                    <Trans>Switch</Trans>
                                    {' '}
                                    <CopyrightLink to="/privacy-policy"><Trans>Privacy Policy</Trans></CopyrightLink>
                                </CopyrightAndLegal>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </StyledFooter>
    );
};


/*
 * Exports
 */
export default Footer;
